import request from "@/utils/request";

// 全部问题列表（PC端）
export function projectLeaderCheckPageall(data: any) {
  return request({
    url: "/project/projectLeaderCheck/pageAll",
    method: "get",
    params: data,
  });
}

// 我创建的
export function projectLeaderCheckPageCreate(data: any) {
  return request({
    url: "/project/projectLeaderCheck/pageCreate",
    method: "get",
    params: data,
  });
}

// 我处理的
export function projectLeaderCheckPageDeal(data: any) {
  return request({
    url: "/project/projectLeaderCheck/pageDeal",
    method: "get",
    params: data,
  });
}

// 删除
export function projectLeaderCheckRemove(id: any) {
  return request({
    url: "/project/projectLeaderCheck/remove/" + id,
    method: "get",
  });
}

// 参建单位列表
export function projectDeptlistProjectCompany(data: any) {
  return request({
    url: "/project/projectDept/pageProjectCompany",
    method: "get",
    params: data,
  });
}

// 获取用户列表(督导检查)
export function pageListUserLeaderCheck(data: any) {
  return request({
    url: "/center/sys/user/pageUserLeaderCheck",
    method: "get",
    params: data,
  });
}

//保存
export function projectLeaderChecksave(data: any) {
  return request({
    url: "/project/projectLeaderCheck/save",
    method: "post",
    data: data,
  });
}

// 检查详情
export function projectDangerHistorygetById(id: any) {
  return request({
    url: "/project/projectLeaderCheck/getById/" + id,
    method: "get",
  });
}

// 问题处理
export function projectDangerHistorysaveDeal(data: any) {
  return request({
    url: "/project/projectLeaderHistory/saveDeal",
    method: "post",
    data: data,
  });
}

// 问题审核
export function projectDangerHistorysaveCheck(data: any) {
  return request({
    url: "/project/projectLeaderHistory/saveCheck",
    method: "post",
    data: data,
  });
}
